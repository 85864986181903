import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";

function AllEduOrders() {
  const token = localStorage.getItem("token");
  const { route, setOnload } = useContext(AppContext);

  const [orders, setOrders] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [search, setSearch] = useState("");
  const [isPop, setIsPop] = useState(false);
  const [delId, setDelId] = useState("");
  const [refresh, setRefresh] = useState(0);


  const handelDelete = function () {
    setOnload(true);
    fetch(`${route}/education/orders/${delId}`, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(() => {
        setRefresh((prev) => prev + 1);
        setDelId("");
        setIsPop(false);
        setOnload(false);
      });
  };


  const virtualPackages = {}; // Object to store package data

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(
          `${route}/education/orders?limit=10000${search ? `&coupon=${search}` : ""}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        let bpackages = [];
        for (const element of data.data) {
          if (!virtualPackages[element.package]) {
            const packageResponse = await fetch(
              `${route}/education/packages/findOne/${element.package}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const packageData = await packageResponse.json();
            virtualPackages[element.package] = { title: packageData.data.title };
          }

          bpackages.push({
            name: (element.user ? element.user.name : null),
            email: (element.user ? element.user.email : null),
            paidAt: element.paidAt,
            totalOrderPrice: element.totalOrderPrice,
            paymentMethodType: element.paymentMethodType,
            title: virtualPackages[element.package].title,
            coupon: element.coupon,
            id: element._id
          });

          bpackages.sort((a, b) => new Date(b.paidAt) - new Date(a.paidAt));

          console.log(bpackages)
        }

        setOrders(bpackages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchOrders();
  }, [refresh, search, route, token]);

  return (

    <div className="main-sec">
      {isPop && (
        <div className="popUp">
          <div>
            <h2>Are You Sure you want to delete this order</h2>
            <div>
              <span className="del" onClick={() => handelDelete()}>
                Yes
              </span>
              <span
                className="edit"
                onClick={() => {
                  setIsPop(false);
                  setDelId("");
                }}
              >
                No
              </span>
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "flex" }}
        onSubmit={(e) => {
          e.preventDefault();
          setSearch(coupon);
        }}
      >
        <input
          type="text"
          style={{ width: "70%", padding: "10px", margin: "30px 0" }}
          onChange={(e) => setCoupon(e.target.value)}
          placeholder="coupon"
        />
        <input
          type="submit"
          style={{
            width: "30%",
            padding: "10px",
            margin: "30px 0",
            backgroundColor: "green",
            border: "none",
            color: "white",
          }}
          value="search"
          className="btn btn-primary"
        />
      </form>
      <h2>All Education Orders</h2>
      <table>
        <thead>
          <tr>
            <td style={{ whiteSpace: "nowrap" }}>User Email</td>
            <td style={{ whiteSpace: "nowrap" }}>User Name</td>
            <td style={{ whiteSpace: "nowrap" }}>Title</td>
            <td style={{ whiteSpace: "nowrap" }}>Paid at</td>
            <td style={{ whiteSpace: "nowrap" }}>Payment Method Type</td>
            <td style={{ whiteSpace: "nowrap" }}>Total Order Price</td>
            <td style={{ whiteSpace: "nowrap" }}>coupon</td>
            <td style={{ whiteSpace: "nowrap" }}>Action</td>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index} >
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{order.email}</td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{order.name}</td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{order.title}</td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                {new Date(order.paidAt).toLocaleDateString()}
              </td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{order.paymentMethodType}</td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{order.totalOrderPrice}$</td>
              <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>{(order.coupon == "" ? "No Coupon" : order.coupon)}</td>
              <td className="actions">
                <div
                  className="del"
                  onClick={() => {
                    setIsPop(true);
                    setDelId(order.id);
                  }}
                >
                  Delete
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h4 style={{ "text-align": "center" }}>Total Orders {orders.length}</h4>
    </div>
  );
}

export default AllEduOrders;
