import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import "./memberpackage.css";


function MembersPackage() {
  const { route, token } = useContext(AppContext);
  const nav = useNavigate();
  const id = useParams().id;
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const packageResponse = await fetch(`${route}/education/packages/PackageData/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
         const packageData = await packageResponse.json(); 

         if (packageData){
          setMembers(packageData.data.users);
         }

/*         if (packageData.data) {
          const users = packageData.data.users;
          let busers = [];

          for (const element of users) {
            const userResponse = await fetch(`${route}/users?_id=${element.user}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });
            const userData = await userResponse.json();

            if (userData.data[0] && userData.data[0].active) {
              const ndata = userData.data[0];
              console.log(ndata)
              busers.push({
                name: ndata.name,
                email: ndata.email,
                end_date: element.end_date,
              });
            }
          }

          setMembers(busers);
        } */
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMembers();
  }, [id, route, token]);

  return (
    <div className="main-sec">
      <div className="table">

      <h2>Members Package</h2>
      <table className="members-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={index}>
              <td>{member.name}</td>
              <td>{member.email}</td>
              <td>{member.endDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
          </div>
    </div>
  );
}

export default MembersPackage;
